<template>
    <div class="action-form">
        <h1
            v-if="$slots.title"
            class="action-form__title"
        >
            <slot name="title"/>
        </h1>
        <p
            v-if="$slots.desc"
            class="action-form__desc"
        >
            <slot name="desc"/>
        </p>
        <form
            novalidate
            @submit.prevent="$emit('submit')"
            class="action-form__content"
        >
            <slot/>
            <div class="action-form__action">
                <base-button
                    type="submit"
                    mode="yellow"
                >
                    {{ actionName }}
                </base-button>
            </div>
        </form>
        <div
            v-if="$listeners.back"
            class="action-form__back"
        >
            <Button
                back
            >
                Назад
            </Button>
        </div>
    </div>
</template>

<script>
import './action-form.scss'

export default {
    name: 'ActionForm',
    props: {
        actionName: {
            type: String,
            default: 'Продолжить'
        }
    }
}
</script>