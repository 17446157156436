<template>
    <ActionForm @submit="submit">
        <template v-slot:desc>
            Укажите номер телефона, который Вы использовали при регистрации.
        </template>
        <Phone
            v-model="form.phone"
            :error="formErrors.phone"
            title="Номер телефона"
        />
    </ActionForm>
</template>

<script>
import Validation from '@/ext/validation/Validation'
import validation from '@/mixins/validation'
import sbg from '@/mixins/sbg'

import sendUnsubscribe from '@/api/sendUnsubscribe'

import Phone from '@/components/form/field/Phone'
import ActionForm from '@/templates/action-form/ActionForm'

export default {
    name: 'UnsubscribePage',
    mixins: [
        validation,
        sbg,
    ],
    data() {
        return {
            form: {
                phone: ''
            },
            formRules: {
                phone: [Validation.REQUIRED, Validation.PHONE]
            }
        }
    },
    methods: {
        async submit() {
            this.validate()

            if (!this.isValid)
                return

            const info = await sendUnsubscribe(this.form)

            let routeName = info.status === 'phoneNotFound' ? 'UnsubscribeInfo' : 'UnsubscribeMessage'
            routeName = info.status === 'sendSMS' ? 'UnsubscribeSms' : routeName

            this.$router.push({
                name: routeName,
                params: {
                    info,
                    data: this.form
                }
            })
        }
    },
    components: {
        Phone,
        ActionForm
    }
}
</script>